:root {
  --color-primary: #d11f2f;
  --color-primary-500: rgba(209, 31, 47, 0.75);
  --color-primary-900: rgb(146, 21, 32);
  --color-primary-300: rgba(209, 31, 47, 0.12);
  --color-primary-200: rgba(209, 31, 47, 0.08);
  --color-secondary: #fafbfc;
  /* ... */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #f8fafc;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
